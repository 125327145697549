/* eslint-disable import/prefer-default-export */

/**
 * 是否是 `PromiseLike<T>` 类型
 * @param target 目标对象
 * @returns 是否是 `PromiseLike<T>` 类型
 */
export function isPromiseLike<T = any>(target: any): target is PromiseLike<T> {
  return Boolean(target) && typeof target.then === 'function';
}
